import { assetsDomain } from '@app/api/fixtures/fixture-domains';
import { FilmGroup } from '@app/api/resources/FilmGroup';

export const newOnMubiFilmGroup: FilmGroup = {
  id: 1345,
  slug: 'new-on-mubi',
  color: 'ac9f92',
  average_colour_hex: '1B2E27',
  focal_point: {
    x: 0.481894,
    y: 0.527228,
  },
  hide_title_on_splash: false,
  double_bill: false,
  image:
    'https://fallback-assets.mubi.com/images/film/199517/cache-317609-1547955160/image-w1280.jpg',
  title: 'Film Of The Day',
  title_upcase: 'FILM OF THE DAY',
  description: '',
  description_html: '',
  full_title: 'Film Of The Day',
  full_title_upcase: 'FILM OF THE DAY',
  title_color: 'ffffff',
  subtitle: null,
  subtitle_upcase: null,
  subtitle_color: 'ffffff',
  short_description: '',
  short_description_html: '',
  title_treatment_url: null,
  trailers: null,
  design_variant: 'default',
  published: true,
  total_items: 30,
  web_url: 'https://mubi-qa.com/collections/new-on-mubi',
  portrait_image: null,
  is_season: false,
};

export const aFilmGroup: FilmGroup = {
  id: 1,
  title: 'Focus on Henri-Georges Clouzot',
  slug: 'clouzot-triple-bill',
  color: '9685ce',
  description:
    'An undisputed master of the crime genre, director Henri-Georges Clouzot crafted some of the most contentious and heart-stopping films of the past century. Kicking off his career in a Nazi-occupied France, he soon found trouble when his breakthrough feature Le Corbeau—an anti-informant fable set within the suspicious community of a provincial town—drew controversy ahead of its release in theaters.\nMost often compared to the likes of Hitchcock, Lang, and Chabrol, his cinema sets out to explore the darkest corners of human instinct while building an ambience of dread—both moral and physical—amongst his disturbing characters.',
  description_html:
    'An undisputed master of the crime genre, director Henri-Georges Clouzot crafted some of the most contentious and heart-stopping films of the past century. Kicking off his career in a Nazi-occupied France, he soon found trouble when his breakthrough feature Le Corbeau—an anti-informant fable set within the suspicious community of a provincial town—drew controversy ahead of its release in theaters.<br />Most often compared to the likes of Hitchcock, Lang, and Chabrol, his cinema sets out to explore the darkest corners of human instinct while building an ambience of dread—both moral and physical—amongst his disturbing characters.',
  image: `${assetsDomain}/images/film_programming_special/350/image-original.jpg?1563297526`,
  title_upcase: 'FOCUS ON HENRI-GEORGES CLOUZOT',
  full_title_upcase: 'FOCUS ON HENRI-GEORGES CLOUZOT',
  title_color: 'ffffff',
  title_treatment_url: null,
  full_title: 'Focus on Henri-Georges Clouzot',
  hide_title_on_splash: false,
  subtitle: null,
  subtitle_color: null,
  subtitle_upcase: null,
  short_description:
    'An undisputed master of the crime genre, director Henri-Georges Clouzot crafted some of the most contentious and heart-stopping films of the past century. Kicking off his career in a Nazi-occupied France, he soon found trouble when his breakthrough feature Le Corbeau—an anti-informant fable set…',
  short_description_html:
    'An undisputed master of the crime genre, director Henri-Georges Clouzot crafted some of the most contentious and heart-stopping films of the past century. Kicking off his career in a Nazi-occupied France, he soon found trouble when his breakthrough feature Le Corbeau—an anti-informant fable set…',
  is_season: false,
  average_colour_hex: '5A442E',
  design_variant: 'default',
  double_bill: false,
  focal_point: {
    x: 0,
    y: 0,
  },
  trailers: null,
  total_items: 3,
  published: true,
  web_url: '',
  portrait_image: {
    url: '',
    focal_point: {
      x: 1,
      y: 1,
    },
  },
};

export const aFilmGroupWithALongTitle: FilmGroup = {
  id: 2,
  title:
    'Acknowledgement, Empathy and Realism: A Dardenne Brothers Double Bill',
  slug: 'empathy-and-realism',
  color: '9685ce',
  description:
    'In a world dismayingly suffused with injustice and cynicism, the humanist films of the Dardenne brothers offer a refreshing and sobering cinematic antidote. Devoting their stellar career to the representation of working-class struggles, the directing duo borrow from Italian neorealism not only its subject matter but its empathetic approach, committing to a truthful portrayal of underrepresented groups.',
  description_html:
    'In a world dismayingly suffused with injustice and cynicism, the humanist films of the Dardenne brothers offer a refreshing and sobering cinematic antidote. Devoting their stellar career to the representation of working-class struggles, the directing duo borrow from Italian neorealism not only its subject matter but its empathetic approach, committing to a truthful portrayal of underrepresented groups.',
  image: `${assetsDomain}/images/film_group/807/image-w960.jpg?1612286669`,
  title_upcase:
    'ACKNOWLEDGEMENT, EMPATHY AND REALISM: A DARDENNE BROTHERS DOUBLE BILL',
  full_title_upcase:
    'ACKNOWLEDGEMENT, EMPATHY AND REALISM: A DARDENNE BROTHERS DOUBLE BILL',
  title_color: 'ffffff',
  title_treatment_url: null,
  full_title: null,
  hide_title_on_splash: false,
  subtitle: null,
  subtitle_color: null,
  subtitle_upcase: null,
  short_description: null,
  short_description_html: null,
  is_season: false,
  average_colour_hex: '5A442E',
  design_variant: 'default',
  double_bill: false,
  focal_point: {
    x: 0,
    y: 0,
  },
  trailers: null,
  total_items: 3,
  published: true,
  web_url: '',
  portrait_image: {
    url: '',
    focal_point: {
      x: 1,
      y: 1,
    },
  },
};

export const aFilmGroupWithALongTitleAndLongSubtitle: FilmGroup = {
  id: 2,
  title:
    'Acknowledgement, Empathy and Realism: A Dardenne Brothers Double Bill',
  subtitle:
    'The Brothers One Two Three Four learning to count five six seven eight',
  slug: 'empathy-and-realism',
  color: '9685ce',
  description:
    'In a world dismayingly suffused with injustice and cynicism, the humanist films of the Dardenne brothers offer a refreshing and sobering cinematic antidote. Devoting their stellar career to the representation of working-class struggles, the directing duo borrow from Italian neorealism not only its subject matter but its empathetic approach, committing to a truthful portrayal of underrepresented groups.',
  description_html:
    'In a world dismayingly suffused with injustice and cynicism, the humanist films of the Dardenne brothers offer a refreshing and sobering cinematic antidote. Devoting their stellar career to the representation of working-class struggles, the directing duo borrow from Italian neorealism not only its subject matter but its empathetic approach, committing to a truthful portrayal of underrepresented groups.',
  image: `${assetsDomain}/images/film_group/807/image-w960.jpg?1612286669`,
  title_upcase:
    'ACKNOWLEDGEMENT, EMPATHY AND REALISM: A DARDENNE BROTHERS DOUBLE BILL',
  full_title_upcase:
    'ACKNOWLEDGEMENT, EMPATHY AND REALISM: A DARDENNE BROTHERS DOUBLE BILL',
  subtitle_upcase:
    'THE BROTHERS ONE TWO THREE FOUR LEARNING TO COUNT FIVE SIX SEVEN EIGHT',

  title_color: 'ffffff',
  title_treatment_url: null,
  full_title: null,
  hide_title_on_splash: false,
  subtitle_color: null,
  short_description: null,
  short_description_html: null,
  is_season: false,
  average_colour_hex: '5A442E',
  design_variant: 'default',
  double_bill: false,
  focal_point: {
    x: 0,
    y: 0,
  },
  trailers: null,
  total_items: 3,
  published: true,
  web_url: '',
  portrait_image: {
    url: '',
    focal_point: {
      x: 1,
      y: 1,
    },
  },
};

export const someFilmGroups: FilmGroup[] = [
  aFilmGroup,
  aFilmGroupWithALongTitle,
  {
    id: 3,
    title: 'THE NEW AUTEURS',
    slug: 'the-new-auteurs',
    color: '68abff',
    description:
      'Distinct visions from emerging artists and rising filmmakers.',
    description_html:
      'Distinct visions from <h1>emerging</h1> artists and rising filmmakers.',
    image: `${assetsDomain}/images/film_programming_special/339/image-original.jpg?1554834951`,
    title_upcase: 'THE NEW AUTEURS',
    full_title_upcase: 'THE NEW AUTEURS',
    title_color: 'ffffff',
    title_treatment_url: null,
    full_title: null,
    hide_title_on_splash: false,
    subtitle: null,
    subtitle_color: null,
    subtitle_upcase: null,
    short_description: null,
    short_description_html: null,
    is_season: false,
    average_colour_hex: '5A442E',
    design_variant: 'default',
    double_bill: false,
    focal_point: {
      x: 0,
      y: 0,
    },
    trailers: null,
    total_items: 3,
    published: true,
    web_url: '',
    portrait_image: {
      url: '',
      focal_point: {
        x: 1,
        y: 1,
      },
    },
  },
  {
    id: 4,
    title: 'THE INNER DEMONS OF INGMAR BERGMAN',
    slug: 'bergman',
    color: '9685ce',
    description:
      '“Film as dream, film as music. No art passes our conscience in the way film does, and goes directly to our feelings, deep down into the dark rooms of our souls. The demons are innumerable, arrive at the most inappropriate times and create panic and terror… but I have learned that if I can master the negative forces and harness them to my chariot, then they can work to my advantage. Lilies often grow out of carcasses’ arseholes.” —Ingmar Bergman',
    description_html:
      '<em>“Film as dream, film as music. No art passes our conscience in the way film does, and goes directly to our feelings, deep down into the dark rooms of our souls. The demons are innumerable, arrive at the most inappropriate times and create panic and terror… but I have learned that if I can master the negative forces and harness them to my chariot, then they can work to my advantage. Lilies often grow out of carcasses’ arseholes.”</em> —Ingmar Bergman',
    image: `${assetsDomain}/images/film_programming_special/140/image-original.jpg?1522152376`,
    title_upcase: 'THE INNER DEMONS OF INGMAR BERGMAN',
    full_title_upcase: 'THE INNER DEMONS OF INGMAR BERGMAN',
    title_color: 'ffffff',
    title_treatment_url: null,
    full_title: null,
    hide_title_on_splash: false,
    subtitle: null,
    subtitle_color: null,
    subtitle_upcase: null,
    short_description: null,
    short_description_html: null,
    is_season: false,
    average_colour_hex: '5A442E',
    design_variant: 'default',
    double_bill: false,
    focal_point: {
      x: 0,
      y: 0,
    },
    trailers: null,
    total_items: 3,
    published: true,
    web_url: '',
    portrait_image: {
      url: '',
      focal_point: {
        x: 1,
        y: 1,
      },
    },
  },
];

export const collectionFilmGroups = [
  {
    id: 1555,
    slug: 'heists',
    color: 'fdd23b',
    average_colour_hex: '121538',
    focal_point: { x: 0.5, y: 0.2 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/1555/cache-918067-1698922247/image-original.png',
    title: 'Master Thieves:',
    title_upcase: 'MASTER THIEVES:',
    description:
      'Making a great heist film is just like pulling off the perfect stick-up. It takes meticulous skill, precise planning, and a dedicated crew of professionals. For the master thief, a well-executed job can offer untold riches. For the filmmaker, it’s an opportunity to put their formal dexterity to the test. The best heist sequences are masterclasses in suspense: set pieces that draw on every element of a director’s stylistic arsenal to ratchet up tension and subvert expectation. We’ve cased and raided the MUBI vaults to bring you a collection of pulse-quickening genre triumphs: films in which criminal masterminds, small-time crooks, and bumbling amateurs stake out the ultimate prize in an adrenalized race against the clock.',
    description_html:
      '\u003cp\u003eMaking a great heist film is just like pulling off the perfect stick-up. It takes meticulous skill, precise planning, and a dedicated crew of professionals. For the master thief, a well-executed job can offer untold riches. For the filmmaker, it\u0026#8217;s an opportunity to put their formal dexterity to the test. The best heist sequences are masterclasses in suspense: set pieces that draw on every element of a director\u0026#8217;s stylistic arsenal to ratchet up tension and subvert expectation. We\u0026#8217;ve cased and raided the MUBI vaults to bring you a collection of pulse-quickening genre triumphs: films in which criminal masterminds, small-time crooks, and bumbling amateurs stake out the ultimate prize in an adrenalized race against the clock.\u003c/p\u003e',
    full_title: 'Master Thieves: Heist Films',
    full_title_upcase: 'MASTER THIEVES: HEIST FILMS',
    title_color: 'ffffff',
    subtitle: 'Heist Films',
    subtitle_upcase: 'HEIST FILMS',
    subtitle_color: 'f8aff6',
    short_description:
      'The best heist movies serve up masterclasses in suspense. We’ve cased and raided our vaults to bring you a collection of pulse-quickening genre triumphs: films in which criminal masterminds, small-time crooks, and bumbling amateurs stake out their prize in an adrenalized race against the clock.',
    short_description_html:
      '\u003cp\u003eThe best heist movies serve up masterclasses in suspense. We\u0026#8217;ve cased and raided our vaults to bring you a collection of pulse-quickening genre triumphs: films in which criminal masterminds, small-time crooks, and bumbling amateurs stake out their prize in an adrenalized race against the clock.\u003c/p\u003e',
    title_treatment_url: null,
    trailers: null,
    design_variant: 'default',
    published: true,
    total_items: 7,
    web_url: 'https://mubi.localhost:3500/collections/heists',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 507,
    slug: 'cannes-film-festival',
    color: 'ff3400',
    average_colour_hex: '4E5D6B',
    focal_point: { x: 0.498195, y: 0.657778 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/507/cache-547732-1662983837/image-original.jpg',
    title: 'Festival Focus: ',
    title_upcase: 'FESTIVAL FOCUS: ',
    description:
      'Evocative of excellence, glamor, and glasses of rosé, Cannes is perhaps the most famous of festivals. Since 1946, the crème de la crème of world cinema have assembled annually on the French Riviera to celebrate a star-studded slate of the year’s finest films. Take a stroll with us down the Boulevard de la Croisette and enjoy a few of our favorites from past editions of this never less than glittering affair.',
    description_html:
      '\u003cp\u003eEvocative of excellence, glamor, and glasses of rosé, Cannes is perhaps the most famous of festivals. Since 1946, the crème de la crème of world cinema have assembled annually on the French Riviera to celebrate a star-studded slate of the year\u0026#8217;s finest films. Take a stroll with us down the Boulevard de la Croisette and enjoy a few of our favorites from past editions of this never less than glittering affair.\u003c/p\u003e',
    full_title: 'Festival Focus:  Cannes Film Festival',
    full_title_upcase: 'FESTIVAL FOCUS:  CANNES FILM FESTIVAL',
    title_color: 'ffffff',
    subtitle: 'Cannes Film Festival',
    subtitle_upcase: 'CANNES FILM FESTIVAL',
    subtitle_color: 'ffffff',
    short_description:
      'Renowned for assembling the crème de la crème of world cinema, Cannes adds some glitz to each spring when it presents its star-studded slate of the finest new films. Stroll with us down the Boulevard de la Croisette and enjoy some of our favorites from past editions of this most famous of festivals.',
    short_description_html:
      '\u003cp\u003eRenowned for assembling the crème de la crème of world cinema, Cannes adds some glitz to each spring when it presents its star-studded slate of the finest new films. Stroll with us down the Boulevard de la Croisette and enjoy some of our favorites from past editions of this most famous of festivals.\u003c/p\u003e',
    title_treatment_url:
      'https://images.mubicdn.net/images/artworks/333946/cache-333946-1625504337/images-original.png',
    trailers: [
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/137/optimised/240p-film-group-trailer-137-en-US.mp4',
        profile: '240p',
      },
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/137/optimised/720p-film-group-trailer-137-en-US.mp4',
        profile: '720p',
      },
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/137/optimised/1080p-film-group-trailer-137-en-US.mp4',
        profile: '1080p',
      },
    ],
    design_variant: 'default',
    published: true,
    total_items: 149,
    web_url: 'https://mubi.localhost:3500/collections/cannes-film-festival',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 1159,
    slug: 'month-of-european-film',
    color: 'fdd23b',
    average_colour_hex: 'EC193A',
    focal_point: { x: 0.5, y: 0.0 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/1159/cache-829499-1668100319/image-original.jpg',
    title: 'Month of European Film',
    title_upcase: 'MONTH OF EUROPEAN FILM',
    description:
      'Spearheaded by the European Film Academy, the Month of European Film celebrates the rich and diverse scope of the continent’s cinema. In collaboration with this new and exciting initiative, this program brings together a selection of treasured classics and contemporary releases from extraordinary filmmakers. From kaleidoscopic animation to powerful dramas, these eclectic gems traverse the breadth of European filmmaking, capturing the hearts of film lovers all over the world.',
    description_html:
      '\u003cp\u003eSpearheaded by the European Film Academy, the Month of European Film celebrates the rich and diverse scope of the continent\u0026#8217;s cinema. In collaboration with this new and exciting initiative, this program brings together a selection of treasured classics and contemporary releases from extraordinary filmmakers. From kaleidoscopic animation to powerful dramas, these eclectic gems traverse the breadth of European filmmaking, capturing the hearts of film lovers all over the world.\u003c/p\u003e',
    full_title: 'Month of European Film',
    full_title_upcase: 'MONTH OF EUROPEAN FILM',
    title_color: 'ffffff',
    subtitle: null,
    subtitle_upcase: null,
    subtitle_color: 'ffffff',
    short_description:
      'In collaboration with the Month of European Film, an exciting initiative from the European Film Academy, we are thrilled to celebrate the rich and diverse cinematic history of Europe. From classics to new releases, these eclectic gems have won the hearts of film lovers all over the world.',
    short_description_html:
      '\u003cp\u003eIn collaboration with the Month of European Film, an exciting initiative from the European Film Academy, we are thrilled to celebrate the rich and diverse cinematic history of Europe. From classics to new releases, these eclectic gems have won the hearts of film lovers all over the world.\u003c/p\u003e',
    title_treatment_url:
      'https://images.mubicdn.net/images/artworks/494066/cache-494066-1668100277/images-original.png',
    trailers: null,
    design_variant: 'spotlight',
    published: true,
    total_items: 39,
    web_url: 'https://mubi.localhost:3500/collections/month-of-european-film',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 569,
    slug: 'romance',
    color: '9685ce',
    average_colour_hex: 'A75B37',
    focal_point: { x: 0.638087, y: 0.351111 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/569/cache-618227-1662983854/image-original.jpg',
    title: 'In the Mood',
    title_upcase: 'IN THE MOOD',
    description:
      'Get ready to fall in love with some of our favorite cinematic romances. Whether it’s the thrill of that first encounter or the depth of a decade-long affair, a quick and dramatic tryst or the bitter regret of a breakup, love can take many shapes and forms, inducing all manner of emotions. Here are some of the best swoon-worthy romances to warm the coldest of hearts.',
    description_html:
      '\u003cp\u003eGet ready to fall in love with some of our favorite cinematic romances. Whether it\u0026#8217;s the thrill of that first encounter or the depth of a decade-long affair, a quick and dramatic tryst or the bitter regret of a breakup, love can take many shapes and forms, inducing all manner of emotions. Here are some of the best swoon-worthy romances to warm the coldest of hearts.\u003c/p\u003e',
    full_title: 'In the Mood for Love',
    full_title_upcase: 'IN THE MOOD FOR LOVE',
    title_color: 'ffd6de',
    subtitle: 'for Love',
    subtitle_upcase: 'FOR LOVE',
    subtitle_color: 'ffffff',
    short_description:
      'Get ready to fall in love with some of our favorite cinematic romances. Whether it’s the thrill of that first encounter or the depth of a decade-long affair, a quick tryst or a bitter breakup, love can take many forms. Here are some of the best swoon-worthy romances to warm the coldest of hearts.',
    short_description_html:
      '\u003cp\u003eGet ready to fall in love with some of our favorite cinematic romances. Whether it\u0026#8217;s the thrill of that first encounter or the depth of a decade-long affair, a quick tryst or a bitter breakup, love can take many forms. Here are some of the best swoon-worthy romances to warm the coldest of hearts.\u003c/p\u003e',
    title_treatment_url: null,
    trailers: [
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/255/optimised/240p-film-group-trailer-255-en-US.mp4',
        profile: '240p',
      },
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/255/optimised/720p-film-group-trailer-255-en-US.mp4',
        profile: '720p',
      },
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/255/optimised/1080p-film-group-trailer-255-en-US.mp4',
        profile: '1080p',
      },
    ],
    design_variant: 'default',
    published: true,
    total_items: 48,
    web_url: 'https://mubi.localhost:3500/collections/romance',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 493,
    slug: 'comedies',
    color: 'fdd23b',
    average_colour_hex: '343021',
    focal_point: { x: 0.483755, y: 0.524444 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/493/cache-619961-1662983855/image-original.jpg',
    title: 'Funny Ha Ha',
    title_upcase: 'FUNNY HA HA',
    description:
      'What the world needs more of is something to laugh about. From slapstick and screwball to arthouse subversion and sharp political satire—all by way of a perfectly timed sight gag or impromptu, unexpected moment: The cinema has a long history of making people laugh, easing their troubles, and poking fun at those in power.',
    description_html:
      '\u003cp\u003eWhat the world needs more of is something to laugh about. From slapstick and screwball to arthouse subversion and sharp political satire\u0026#8212;all by way of a perfectly timed sight gag or impromptu, unexpected moment: The cinema has a long history of making people laugh, easing their troubles, and poking fun at those in power.\u003c/p\u003e',
    full_title: 'Funny Ha Ha',
    full_title_upcase: 'FUNNY HA HA',
    title_color: 'ffffff',
    subtitle: null,
    subtitle_upcase: null,
    subtitle_color: 'ffffff',
    short_description:
      'What the world needs more of is something to laugh about. From slapstick and screwball to arthouse subversion and sharp political satire, this comedy film group has plenty to soothe the soul and poke fun at those in power.',
    short_description_html:
      '\u003cp\u003eWhat the world needs more of is something to laugh about. From slapstick and screwball to arthouse subversion and sharp political satire, this comedy film group has plenty to soothe the soul and poke fun at those in power.\u003c/p\u003e',
    title_treatment_url: null,
    trailers: null,
    design_variant: 'default',
    published: true,
    total_items: 48,
    web_url: 'https://mubi.localhost:3500/collections/comedies',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 1085,
    slug: 'mubi-podcast',
    color: 'fdd23b',
    average_colour_hex: '3C220B',
    focal_point: { x: 0.348963, y: 0.495556 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/1085/cache-776836-1680177266/image-original.jpeg',
    title: 'Featured on the MUBI Podcast',
    title_upcase: 'FEATURED ON THE MUBI PODCAST',
    description:
      'From deep dives through the hidden backwaters of film history, to up-to-the-minute interviews with some of the biggest names in international cinema, every episode of the MUBI Podcast is a journey of discovery. Bringing together some of the films featured on the show, this collection of cinematic treasures from around the world is the perfect accompaniment to the fascinating cultural histories unearthed each week.',
    description_html:
      '\u003cp\u003eFrom deep dives through the hidden backwaters of film history, to up-to-the-minute interviews with some of the biggest names in international cinema, every episode of the \u003ca href="https://podcasts.apple.com/gb/podcast/mubi-podcast/id1569229544"\u003eMUBI Podcast\u003c/a\u003e is a journey of discovery. Bringing together some of the films featured on the show, this collection of cinematic treasures from around the world is the perfect accompaniment to the fascinating cultural histories unearthed each week.\u003c/p\u003e',
    full_title: 'Featured on the MUBI Podcast',
    full_title_upcase: 'FEATURED ON THE MUBI PODCAST',
    title_color: 'ffffff',
    subtitle: null,
    subtitle_upcase: null,
    subtitle_color: 'ffffff',
    short_description:
      'Every episode of the MUBI Podcast is a thrilling journey of discovery through the work of some of the world’s greatest filmmakers. Featuring films mentioned on the show, this dazzling collection of global cinematic treasures is the perfect accompaniment to the cultural histories unearthed each week.',
    short_description_html:
      '\u003cp\u003eEvery episode of the MUBI Podcast is a thrilling journey of discovery through the work of some of the world\u0026#8217;s greatest filmmakers. Featuring films mentioned on the show, this dazzling collection of global cinematic treasures is the perfect accompaniment to the cultural histories unearthed each week.\u003c/p\u003e',
    title_treatment_url:
      'https://images.mubicdn.net/images/artworks/462316/cache-462316-1651060593/images-original.png',
    trailers: null,
    design_variant: 'default',
    published: true,
    total_items: 9,
    web_url: 'https://mubi.localhost:3500/collections/mubi-podcast',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 492,
    slug: 'women-directors',
    color: '6596e7',
    average_colour_hex: '63417D',
    focal_point: { x: 0.46, y: 0.47 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/492/cache-546708-1680272042/image-original.jpg',
    title: 'Reframing:',
    title_upcase: 'REFRAMING:',
    description:
      'For as long as the movies have existed, women have been making them. This goes right back to the birth of the industry, when Alice Guy-Blaché, coming up through the studio of Léon Gaumont, directed one of the first narrative films, The Cabbage Fairy, back in 1896. And it runs up to the present moment, when increasing numbers of women directors are breaking through in an industry that for too long has held them back. This collection showcases work by women auteurs spanning a diverse array of themes, styles, and genres. It’s time to reframe the conversation and focus our lens on films outside of the male-dominated canon of cinema.',
    description_html:
      '\u003cp\u003eFor as long as the movies have existed, women have been making them. This goes right back to the birth of the industry, when Alice Guy-Blaché, coming up through the studio of Léon Gaumont, directed one of the first narrative films, \u003cem\u003eThe Cabbage Fairy\u003c/em\u003e, back in 1896. And it runs up to the present moment, when increasing numbers of women directors are breaking through in an industry that for too long has held them back. This collection showcases work by women auteurs spanning a diverse array of themes, styles, and genres. It\u0026#8217;s time to reframe the conversation and focus our lens on films outside of the male-dominated canon of cinema.\u003c/p\u003e',
    full_title: 'Reframing: Women Directors',
    full_title_upcase: 'REFRAMING: WOMEN DIRECTORS',
    title_color: 'ffffff',
    subtitle: 'Women Directors',
    subtitle_upcase: 'WOMEN DIRECTORS',
    subtitle_color: 'ffffff',
    short_description:
      'This collection showcases work by women auteurs spanning a diverse array of themes, styles, and genres. It’s time to reframe the conversation and focus our lens on films outside of the male-dominated canon of cinema.',
    short_description_html:
      '\u003cp\u003eThis collection showcases work by women auteurs spanning a diverse array of themes, styles, and genres. It\u0026#8217;s time to reframe the conversation and focus our lens on films outside of the male-dominated canon of cinema.\u003c/p\u003e',
    title_treatment_url: null,
    trailers: [
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/272/optimised/240p-film-group-trailer-272-en-US.mp4',
        profile: '240p',
      },
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/272/optimised/720p-film-group-trailer-272-en-US.mp4',
        profile: '720p',
      },
      {
        url: 'https://trailers-dev.mubicdn.net/film_group/trailers/272/optimised/1080p-film-group-trailer-272-en-US.mp4',
        profile: '1080p',
      },
    ],
    design_variant: 'default',
    published: true,
    total_items: 154,
    web_url: 'https://mubi.localhost:3500/collections/women-directors',
    portrait_image: null,
    is_season: false,
  },
  {
    id: 918,
    slug: 'spotlight-uk',
    color: '60dfff',
    average_colour_hex: '6978A9',
    focal_point: { x: 0.5, y: 0.0 },
    hide_title_on_splash: false,
    double_bill: false,
    image:
      'https://images.mubicdn.net/images/film_group/918/cache-687040-1685703076/image-original.jpg',
    title: 'MUBI Spotlight',
    title_upcase: 'MUBI SPOTLIGHT',
    description:
      'Showcasing some of the most inspiring and compelling new films and restorations, fresh from cinemas and festivals. A world of bold new discoveries awaits you, exclusively on MUBI.',
    description_html:
      '\u003cp\u003eShowcasing some of the most inspiring and compelling new films and restorations, fresh from cinemas and festivals. A world of bold new discoveries awaits you, exclusively on MUBI.\u003c/p\u003e',
    full_title: 'MUBI Spotlight',
    full_title_upcase: 'MUBI SPOTLIGHT',
    title_color: 'ffffff',
    subtitle: null,
    subtitle_upcase: null,
    subtitle_color: 'ffffff',
    short_description:
      'Showcasing some of the most inspiring and compelling new films and restorations, fresh from cinemas and festivals. A world of bold new discoveries awaits you, exclusively on MUBI.',
    short_description_html:
      '\u003cp\u003eShowcasing some of the most inspiring and compelling new films and restorations, fresh from cinemas and festivals. A world of bold new discoveries awaits you, exclusively on MUBI.\u003c/p\u003e',
    title_treatment_url: null,
    trailers: null,
    design_variant: 'default',
    published: true,
    total_items: 70,
    web_url: 'https://mubi.localhost:3500/collections/spotlight-uk',
    portrait_image: null,
    is_season: false,
  },
];
