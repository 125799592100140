import { shallowEqual, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';

import { Promo } from '@app/api/resources/Promo';

import { OnboardingFlowTrigger } from '@app/onboarding/services/onboarding-types';
import {
  checkPathnamesAreEqual,
  getFullRouteUrl,
  removeLanguageAndCountryPrefixFromPath,
} from '@app/services/routeHelpers';

import {
  setOnboardingContext,
  setShowOnboardingModal,
} from '@app/actions/AppStateActions';
import { OnboardingContext } from '@app/reducers/AppStateReducer';

import useI18nUrlData, { I18nUrlData } from '@app/hooks/helpers/useI18nUrlData';
import useAppSelector from '@app/hooks/utils/useAppSelector';

type OnboardingContextOptions = Partial<OnboardingContext>;

const giftPaymentContext = (
  { contextData, container }: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
  t: Translate,
): OnboardingContext => ({
  flow: 'paymentDetailsNew',
  contextData: {
    purchasable: 'gift',
    submitCopy: t('gifts:gifts.schedule_and_pay.cta'),
    submitPath: `/services/api/gifts/${contextData.giftToken}/pay`,
    successPath: getFullRouteUrl({
      url: `/gifts/${contextData.giftToken}/confirmation`,
      i18nUrlData,
      includeDomain: false,
    }),
    ui: { isEmbedded: true },
  },
  container,
});

const giftSignupContext = (i18nUrlData: I18nUrlData): OnboardingContext => ({
  flow: 'signup',
  contextData: {
    redirectUrl: getFullRouteUrl({
      url: '/gifts/new',
      i18nUrlData,
      includeDomain: false,
    }),
  },
});

const freeOnboardingContext = (
  context: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => {
  const redirectUrl =
    removeLanguageAndCountryPrefixFromPath(
      context?.contextData?.redirectUrl || '',
    ) || '/showing';

  return {
    flow: 'signup',
    contextData: {
      signUpRequestParams: { via_free_film: true },
      signUpTextKey: 'free_film',
      titleKey: 'header_promo',
      redirectUrl: getFullRouteUrl({
        url: redirectUrl,
        i18nUrlData,
        includeDomain: false,
      }),
    },
  };
};

const trialNewContext = (
  context: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => {
  const ctx: OnboardingContext = {
    flow: 'trialNew',
    contextData: context?.contextData,
  };

  if (context?.contextData?.redirectUrl) {
    const redirectUrl =
      removeLanguageAndCountryPrefixFromPath(context.contextData.redirectUrl) ||
      '/showing';

    ctx.contextData = {
      ...context?.contextData,
      redirectUrl: getFullRouteUrl({
        url: redirectUrl,
        i18nUrlData,
        includeDomain: false,
      }),
    };
  }

  return ctx;
};

const subscribeContext = (
  context: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => {
  const ctx: OnboardingContext = {
    flow: 'subscribeFromEmailForm',
    contextData: context?.contextData,
  };

  if (context?.contextData?.redirectUrl) {
    const redirectUrl =
      removeLanguageAndCountryPrefixFromPath(context.contextData.redirectUrl) ||
      '/showing';

    ctx.contextData = {
      ...context?.contextData,
      redirectUrl: getFullRouteUrl({
        url: redirectUrl,
        i18nUrlData,
        includeDomain: false,
      }),
    };
  }

  return ctx;
};

const tvActivationContext = (
  { contextData }: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => ({
  flow: 'tvActivationNew',
  contextData: {
    ...contextData,
    redirectUrl: getFullRouteUrl({
      url: '/showing',
      i18nUrlData,
      includeDomain: false,
    }),
  },
});

const referralContext = (
  { contextData }: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => ({
  flow: 'referralNew',
  contextData: {
    ...contextData,
    onboardingContext: 'referral',
    redirectUrl: getFullRouteUrl({
      url: '/showing',
      i18nUrlData,
      includeDomain: false,
    }),
  },
});

const changePlanContext = (
  context: OnboardingContextOptions,
): OnboardingContext => ({
  flow: 'changePlanNew',
  contextData: context?.contextData,
});

const newPaymentMethodContext = (
  { container }: OnboardingContextOptions,
  activeSubscriber: boolean,
  subscriptionExpiresAt: string,
  t: Translate,
): OnboardingContext => {
  const context: OnboardingContext = {
    flow: 'paymentDetailsNew',
    contextData: {
      cancelPath: '/subscription',
      forceNewPaymentMethod: true,
      purchasable: 'subscription_monthly',
      submitCopy: t('common:common.buttons.update'),
      cancelPaymentCopy: t('common:common.buttons.cancel'),
      submitPath: '/services/api/payment_method',
      successPath: '/subscription',
      ui: { isEmbedded: true },
    },
    container,
  };

  if (activeSubscriber && subscriptionExpiresAt) {
    context.contextData.submitParams = JSON.stringify({
      subscription_expires_at: subscriptionExpiresAt,
    });
  }

  return context;
};

const authNewContext = (
  flow: 'signup' | 'loginNew',
  context: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => {
  // on /login page we respect the return_to param
  const urlSearchParams = new URLSearchParams(window.location.search);
  const returnUrl = urlSearchParams.get('return_to');
  const urlPath = returnUrl || context?.contextData?.redirectUrl;

  const ctx: OnboardingContext = {
    flow,
    contextData: context?.contextData,
  };

  if (urlPath) {
    ctx.contextData = {
      ...context?.contextData,
      redirectUrl:
        returnUrl ||
        getFullRouteUrl({
          url: '/showing',
          i18nUrlData,
          includeDomain: false,
        }),
    };
  }

  return ctx;
};

const trialFromEmailFormContext = (
  context: OnboardingContextOptions,
): OnboardingContext => ({
  flow: 'trialFromEmailForm',
  contextData: context?.contextData,
});

const subscribeFromEmailFormContext = (
  context: OnboardingContextOptions,
): OnboardingContext => ({
  flow: 'subscribeFromEmailForm',
  contextData: context?.contextData,
});

const promoOrSubscribeOrTrialOnboardingContext = (
  context: OnboardingContextOptions,
  onboardingPromo: Promo,
  i18nUrlData: I18nUrlData,
  trialsEnabled: boolean,
) => {
  if (onboardingPromo) {
    return promoNewContext(context, i18nUrlData, onboardingPromo);
  }
  if (!trialsEnabled) {
    return subscribeContext(context, i18nUrlData);
  }
  return trialNewContext(context, i18nUrlData);
};

const giftFilmShareContext = ({
  contextData: { giftFilmToken, filmId, email, name, senderName },
}: OnboardingContextOptions): OnboardingContext => ({
  flow: 'giftFilmShare',
  contextData: {
    giftFilmToken,
    filmId,
    email,
    name,
    senderName,
  },
});

const studentNewContext = (
  context: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
): OnboardingContext => ({
  flow: 'studentNew',
  contextData: {
    ...context?.contextData,
    redirectUrl: getFullRouteUrl({
      url: '/showing',
      i18nUrlData,
      includeDomain: false,
    }),
  },
});

const notebookSubContext = (
  context: OnboardingContextOptions,
): OnboardingContext => ({
  flow: 'notebookSub',
  contextData: context?.contextData,
});

const promoNewContext = (
  context: OnboardingContextOptions,
  i18nUrlData: I18nUrlData,
  specialPromo?: Promo,
): OnboardingContext => {
  let redirectUrl = '/showing';

  if (context?.contextData?.redirectUrl) {
    redirectUrl = removeLanguageAndCountryPrefixFromPath(
      context.contextData.redirectUrl,
    );
  }

  const ctx: OnboardingContext = {
    flow: 'promoNew',
    specialPromo,
    ...context,
  };

  if (context?.contextData?.redirectUrl) {
    ctx.contextData = {
      ...context?.contextData,
      redirectUrl: getFullRouteUrl({
        url: redirectUrl,
        i18nUrlData,
        includeDomain: false,
      }),
    };
  }

  return ctx;
};

const paymentDetailsNewContext = (
  context: OnboardingContextOptions,
): OnboardingContext => ({
  flow: 'paymentDetailsNew',
  contextData: {
    ...context?.contextData,
    ui: { isEmbedded: true },
  },
});

const verifyContext = (
  context: OnboardingContextOptions,
): OnboardingContext => ({
  flow: 'verify',
  contextData: context?.contextData,
});

export const getOnboardingContext = (
  trigger: OnboardingFlowTrigger,
  onboardingContext: OnboardingContextOptions,
  onboardingPromo: Promo,
  i18nUrlData: I18nUrlData,
  activeSubscriber: boolean,
  trialsEnabled: boolean,
  subscriptionExpiresAt: string,
  t: Translate,
): OnboardingContext => {
  if (trigger === 'free') {
    return freeOnboardingContext(onboardingContext, i18nUrlData);
  }
  if (trigger === 'catalogue') {
    return promoOrSubscribeOrTrialOnboardingContext(
      onboardingContext,
      onboardingPromo,
      i18nUrlData,
      trialsEnabled,
    );
  }
  if (trigger === 'gift') {
    return giftPaymentContext(onboardingContext, i18nUrlData, t);
  }
  if (trigger === 'giftSignup') {
    return giftSignupContext(i18nUrlData);
  }
  if (trigger === 'tvActivation' || trigger === 'tvActivationNew') {
    return tvActivationContext(onboardingContext, i18nUrlData);
  }
  if (trigger === 'referralNew') {
    return referralContext(onboardingContext, i18nUrlData);
  }
  if (trigger === 'loginNew' || trigger === 'signup') {
    return authNewContext(trigger, onboardingContext, i18nUrlData);
  }
  if (trigger === 'changePlanNew') {
    return changePlanContext(onboardingContext);
  }

  if (trigger === 'newPaymentMethod') {
    return newPaymentMethodContext(
      onboardingContext,
      activeSubscriber,
      subscriptionExpiresAt,
      t,
    );
  }

  if (trigger === 'giftFilmShare') {
    return giftFilmShareContext(onboardingContext);
  }

  if (trigger === 'studentNew') {
    return studentNewContext(onboardingContext, i18nUrlData);
  }

  if (trigger === 'trialFromEmailForm') {
    return trialFromEmailFormContext(onboardingContext);
  }

  if (trigger === 'subscribeFromEmailForm') {
    return subscribeFromEmailFormContext(onboardingContext);
  }

  if (trigger === 'notebookSub') {
    return notebookSubContext(onboardingContext);
  }

  if (trigger === 'promoNew') {
    return promoNewContext(onboardingContext, i18nUrlData);
  }

  if (trigger === 'trialNew') {
    return trialNewContext(onboardingContext, i18nUrlData);
  }

  if (trigger === 'paymentDetailsNew') {
    return paymentDetailsNewContext(onboardingContext);
  }

  if (trigger === 'verify') {
    return verifyContext(onboardingContext);
  }

  return null;
};

export type OnboardingTriggerFunc = (
  trigger: OnboardingFlowTrigger,
  context?: OnboardingContextOptions,
) => void;

const useOnboardingTrigger = () => {
  const dispatch = useDispatch();
  const redirectAfterLogin = ['/memberships', '/student'];

  const i18nUrlData = useI18nUrlData();

  const {
    onboardingPromo,
    activeSubscriber,
    subscriptionExpiresAt,
    isAuthenticated,
    trialsEnabled,
  } = useAppSelector(
    state => ({
      onboardingPromo: state.appState?.onboardingConfig?.promo,
      activeSubscriber: state.user.activeSubscriber,
      subscriptionExpiresAt: state.user.user?.subscription?.expires_at,
      isAuthenticated: state.user.isAuthenticated,
      trialsEnabled: state.appState?.trialsEnabled,
    }),
    shallowEqual,
  );
  const { t } = useTranslation('common');

  const router = useRouter();

  const addForcePlanIfNecessary = (
    context: OnboardingContextOptions,
  ): OnboardingContextOptions => {
    if (context?.contextData?.forcePlan) {
      return context;
    }
    if (
      (checkPathnamesAreEqual(router.pathname, '/films/[filmSlug]') ||
        checkPathnamesAreEqual(router.pathname, '/films/[filmSlug]/trailer')) &&
      !isAuthenticated
    ) {
      return {
        ...context,
        contextData: { ...context?.contextData, forcePlan: 'month' },
      };
    }
    return context;
  };

  const contextTransformer = (
    context: OnboardingContextOptions,
  ): OnboardingContextOptions => {
    let ctx = { ...context };

    for (const path of redirectAfterLogin) {
      if (checkPathnamesAreEqual(router.pathname, path)) {
        ctx = {
          ...context,
          contextData: {
            ...context?.contextData,
            redirectUrl: '/showing',
          },
        };
      }
    }

    return addForcePlanIfNecessary(ctx);
  };

  const triggerModal: OnboardingTriggerFunc = (trigger, context) => {
    const onboardingContext = getOnboardingContext(
      trigger,
      contextTransformer(context),
      onboardingPromo,
      i18nUrlData,
      activeSubscriber,
      trialsEnabled,
      subscriptionExpiresAt,
      t,
    );
    dispatch(setOnboardingContext(onboardingContext));
    dispatch(setShowOnboardingModal(true));
  };

  return triggerModal;
};

export default useOnboardingTrigger;
