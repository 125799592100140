import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';
import getT from 'next-translate/getT';
import useTranslation from 'next-translate/useTranslation';

import {
  getAcceptableLanguageToRecommend,
  getLanguageName,
  LocalePath,
} from '@app/api/services/language';
import { changeLanguagePrefixOfUrl } from '@app/services/routeHelpers';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useGetLocaleMismatchBannerContent = () => {
  const { lang } = useTranslation();
  const { acceptedLanguages, userSelectedLocale, geoLocation } = useAppSelector(
    state => ({
      acceptedLanguages: state.user.acceptedLanguages,
      userSelectedLocale: state.user.user?.locale,
      geoLocation: state.user.geoLocation,
    }),
    shallowEqual,
  );

  const router = useRouter();

  const [message, setMessage] = useState<string>(null);
  const [linkText, setLinkText] = useState<string>(null);
  const [linkUrl, setLinkUrl] = useState<string>(null);

  const setLocaleMismatchBannerContentToShow = async (
    acceptableLanguage: LocalePath,
  ) => {
    const fixedLanguageTranslator = await getT(acceptableLanguage, 'common');
    setMessage(
      fixedLanguageTranslator('common.locale_notice.notice_full', {
        language: getLanguageName(acceptableLanguage),
      }),
    );
    setLinkText(
      fixedLanguageTranslator('common.locale_notice.view_locale', {
        language: getLanguageName(acceptableLanguage),
      }),
    );
    setLinkUrl(
      changeLanguagePrefixOfUrl(router.asPath, acceptableLanguage, geoLocation),
    );
  };

  useEffect(() => {
    const acceptableLanguageToShow = getAcceptableLanguageToRecommend(
      lang,
      acceptedLanguages,
      userSelectedLocale,
    );

    if (acceptableLanguageToShow) {
      setLocaleMismatchBannerContentToShow(acceptableLanguageToShow);
    }
  }, [router.locale]);

  return [message, linkText, linkUrl];
};

export default useGetLocaleMismatchBannerContent;
