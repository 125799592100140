import { someCollections } from '@app/api/fixtures/collections';
import HttpService from '@app/api/http-service';
import { Meta } from '@app/api/pagination';
import { Film, FilmId } from '@app/api/resources/Film';
import { FilmGroup, FilmGroupId } from '@app/api/resources/FilmGroup';
import { ObjectOfStrings } from '@app/api/utility-types';
import { getPaginationQS } from '@app/api/utils';

export type CollectionVariant =
  | 'marquee'
  | 'go_banner' // - go film of the week
  | 'coming_soon_banner' // - a single film that's not yet available for streaming but coming soon
  | 'default' // - a plain collection
  | 'film_group_spotlight' // - a spotlighted film group
  | 'double_height'; // - (special treatment for releases collection) - special treatment of a plain collection

type CollectionSharedProperties = {
  type: CollectionVariant;
  total_items: number;
  slug: string;
  links_to_details?: boolean;
  title?: string;
  header_image?: string;
  description?: string;
  description_html?: string;
  web_url?: string;
};

export type Collection = CollectionSharedProperties & {
  film_group: FilmGroup | null;
  films: Film[];
};

export type CollectionDeduped = CollectionSharedProperties & {
  film_group_id: FilmGroupId;
  film_ids: FilmId[];
};

export const getCollection = ({
  httpContext,
  collectionSlug,
}: {
  httpContext: ObjectOfStrings;
  collectionSlug: string;
}): Promise<{
  data: Collection;
}> => HttpService(httpContext).get(`/collections/${collectionSlug}`);

export const getCollections = ({
  httpContext,
  perPage,
  pageNum = 1,
}: {
  httpContext: ObjectOfStrings;
  perPage: number;
  pageNum?: number;
}): Promise<{
  data: {
    collections: Collection[];
  };
}> => {
  if (process.env.MUBI_ENV === 'development') {
    return new Promise(resolve => {
      resolve({ data: { collections: someCollections } });
    });
  }

  const paginationQs = getPaginationQS(pageNum, perPage);
  return HttpService(httpContext).get(
    `/collections${paginationQs ? `?${paginationQs}` : ''}`,
  );
};

export const getCollectionFilms = ({
  httpContext,
  collectionSlug,
  pageNum = 1,
  perPage,
}): Promise<{
  data: {
    films: Film[];
    meta: Meta;
  };
}> => {
  const paginationQs = getPaginationQS(pageNum, perPage);
  return HttpService(httpContext).get(
    `/collections/${collectionSlug}/films${
      paginationQs ? `?${paginationQs}` : ''
    }`,
  );
};

export const getCollectionFilmsExpired = ({
  httpContext,
  collectionSlug,
  pageNum = 1,
  perPage,
}): Promise<{
  data: {
    films: Film[];
    meta: Meta;
  };
}> => {
  const paginationQs = getPaginationQS(pageNum, perPage);
  return HttpService(httpContext).get(
    `/collections/${collectionSlug}/films/expired${
      paginationQs ? `?${paginationQs}` : ''
    }`,
  );
};

export const getDedupedCollection = (collection: Collection) => {
  const collectionFilms = collection.films;

  const sectionFilmIds = collectionFilms.map(film => film.id);
  const sectionFilmGroupId = collection.film_group?.id;

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    films,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    film_group,
    ...sectionCollectionWithoutFilmsOrFilmGroup
  } = collection;

  const collectionDeduped = {
    ...sectionCollectionWithoutFilmsOrFilmGroup,
    film_ids: sectionFilmIds,
    film_group_id: sectionFilmGroupId,
  };

  return collectionDeduped;
};
