import { getCollectionFilms } from '@app/api/resources/Collections';

import { ObjectOfStrings } from '@app/types/utility-types';

export const getSplashContentPropsForCountry = (country: string) => {
  if (country === 'MY') {
    return {
      devicesImageFilename: 'malaysia/abang_long.jpg',
      communityImageFilename: 'malaysia/my_stupid_boss.jpg',
      trendingFilmsCopy: 'showing',
    };
  }
  if (country === 'IN') {
    return {
      devicesImageFilename: 'darjeeling_limited_dark.jpg',
      communityImageFilename: 'grand_budapest_hotel_dark.jpg',
      trendingFilmsCopy: 'india_trending',
    };
  }
  return {
    devicesImageFilename: 'darjeeling_limited_dark.jpg',
    communityImageFilename: 'grand_budapest_hotel_dark.jpg',
    trendingFilmsCopy: 'showing',
  };
};

const initialiseFilmGroupFilms = async (
  httpContext: ObjectOfStrings,
  collectionSlug: string,
  perPage: number = 15,
) => {
  const {
    data: { films },
  } = await getCollectionFilms({
    httpContext,
    collectionSlug,
    perPage,
  });
  return films;
};

export const initialiseTrendingFilms = async (
  httpContext: ObjectOfStrings,
  country: string,
) => {
  if (country === 'IN') {
    // we want to end up with 15 films: 9 india films, and 6 trending films, interspliced
    const [indiaFilms, trendingFilms] = await Promise.all([
      initialiseFilmGroupFilms(httpContext, 'mubiindia', 9),
      // fetch a few extra trending films, in case there are dupes we have to filter out later
      initialiseFilmGroupFilms(httpContext, 'trending', 15),
    ]);
    const indiaFilmsIds = indiaFilms.map(film => film.id);
    const filteredTrendingFilms = trendingFilms.filter(
      film => !indiaFilmsIds.includes(film.id),
    );
    return [
      ...indiaFilms.slice(0, 3),
      ...filteredTrendingFilms.slice(0, 3),
      ...indiaFilms.slice(3, 6),
      ...filteredTrendingFilms.slice(3, 6),
      ...indiaFilms.slice(6, 9),
    ];
  }

  const rotatingFilms = await initialiseFilmGroupFilms(
    httpContext,
    'trending',
    15,
  );
  return rotatingFilms;
};
