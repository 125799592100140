import { SetStateAction, useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import styled from '@emotion/styled';

import { UserStateBannerType } from '@app/api/resources/User';

import { ONE_HOUR_AS_FRACTION_OF_DAY } from '@app/services/date-utils';

import { color as themeColors } from '@app/themes/mubi-theme';

import { CloseIcon } from '@app/components/icons/CommonIcons';
import PageSection from '@app/components/layout/PageSection';

type UserStateBannerProps = {
  messageComponent?: JSX.Element;
  messageString?: string;
  dismissCookieName?: string;
  dismissLengthInDays?: number;
  backgroundColor?: string;
  fontColor?: string;
  messageType?: UserStateBannerType;
  allowDismiss?: boolean;
  onDismiss?: () => void;
};

const UserStateBanner = ({
  messageComponent,
  messageString = '',
  dismissCookieName,
  dismissLengthInDays = ONE_HOUR_AS_FRACTION_OF_DAY,
  backgroundColor = null,
  fontColor = null,
  messageType = 'notice',
  allowDismiss = true,
  onDismiss = () => {},
}: UserStateBannerProps) => {
  const [dismissNotice, setDismissNotice] =
    useState<SetStateAction<string | boolean>>(false);

  useEffect(() => {
    if (dismissCookieName) {
      setDismissNotice(Cookie.get(dismissCookieName));
    }
  }, [dismissCookieName]);

  const dismissBanner = () => {
    if (dismissCookieName) {
      const options: { expires?: number } = {};
      if (dismissLengthInDays) {
        options.expires = dismissLengthInDays;
      }
      Cookie.set(dismissCookieName, 'true', options);
    }
    setDismissNotice(true);
    onDismiss();
  };

  const getBackgroundColor = (): string => {
    if (backgroundColor) {
      return backgroundColor;
    }

    if (messageType === 'alert') {
      return themeColors.alertRed;
    }
    return themeColors.lightBlue;
  };

  const getFontColor = (): string => {
    if (fontColor) {
      return fontColor;
    }
    if (messageType === 'alert') {
      return themeColors.white;
    }
    return themeColors.darkText;
  };

  const getMessage = () => {
    if (messageComponent) {
      return (
        <MessageContainer fontColor={getFontColor()}>
          {messageComponent}
        </MessageContainer>
      );
    }
    return (
      <MessageContainer
        dangerouslySetInnerHTML={{
          __html: messageString,
        }}
        fontColor={getFontColor()}
      />
    );
  };

  if (!dismissNotice && (messageComponent || messageString)) {
    return (
      <Container backgroundColor={getBackgroundColor()}>
        <PageSection fromBreakpoint="tablet">
          <InnerContainer>
            {getMessage()}

            <CloseButtonPosition>
              {allowDismiss && (
                <CloseButton onClick={dismissBanner}>
                  <CloseIcon width="12px" color={getFontColor()} />
                </CloseButton>
              )}
            </CloseButtonPosition>
          </InnerContainer>
        </PageSection>
      </Container>
    );
  }
  return null;
};

const Container = styled.div<{ backgroundColor: string }>`
  padding: 14px 0;
  font-weight: bold;
  font-size: 14px;
  background-color: ${props => props.backgroundColor};
  z-index: 1;
  position: relative;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
`;

const InnerContainer = styled.div`
  position: relative;
`;

const MessageContainer = styled.div<{ fontColor: string }>`
  width: calc(100% - 25px);
  color: ${props => props.fontColor};

  & a {
    color: ${props => props.fontColor};
    text-decoration: underline;
  }
`;

const CloseButtonPosition = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;
const CloseButton = styled.div`
  cursor: pointer;
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 0.7;
  }
  position: relative;
  // Clickable area
  &:after {
    content: '';
    padding: 15px;
    position: absolute;
    top: -7.5px;
    left: -7.5px;
  }
`;

export default UserStateBanner;
